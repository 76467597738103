









































import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KText from '@/@core/components/typography/KText.vue';
import useTableOptions from '@/@core/helpers/table-options';
import HelloWorld from '@/components/HelloWorld.vue';
import { useIndustryStore } from '@/modules/industry/services/store';
import { useMetaStore } from '@/modules/meta/services/store';
import { useProductStore } from '@/modules/product/services/store';
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import Footer from '../../ui/Footer.vue';
import Hero from '../../ui/Hero.vue';
import Icon from '../../ui/Icon.vue';
import Navigations from '../../ui/Navigations.vue';
import StatisticList from '../../ui/StatisticList.vue';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    HelloWorld,
    KCard,
    Hero,
    Navigations,
    Icon,
    FooterSection: Footer,
    StatisticList
  },
  name: 'LandingDefaultPage',
  setup() {
    
        const store = useMetaStore()
        const industryStore = useIndustryStore();
        const productStore = useProductStore();
        const metaData = computed(() => store.metas.length ? store.metas[0] : {})
        const renderKey = ref(0)
        const isLoading = ref(false)

        const init = async () => {
          isLoading.value = true

          await industryStore.getStatisticIndustry({
            filter: {},
            options: useTableOptions(),
          })
          // await industryStore.getManyIndustry({
          //   filter: {},
          //   options: useTableOptions(),
          // })
          // await productStore.getManyProduct()
          await store.getManyMeta()
          renderKey.value ++

          isLoading.value = false
        }

        onMounted(async () => await init())

        return {
            store,
            metaData,
            renderKey,
            industryStore,
            productStore,
            isLoading
        }
  }
});
