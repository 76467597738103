





























































import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { KCard, KText },
  name: 'LandingHero',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const gotoGoogleForm = () => {
      const hasData = props.data
      if (!hasData) return

      // @ts-ignore
      window.open(props.data?.googleFormLink, '_blank').focus();
    }

    return {
      isMobile,
      gotoGoogleForm
    };
  }
});
